// import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

export function getToken() {
    return localStorage.getItem(TokenKey);
}

export function setToken(token) {
    return localStorage.setItem(TokenKey, token);
}

export function removeToken() {
    return localStorage.removeItem(TokenKey);
}

export function getForm() {
    return localStorage.getItem('FormKey');
}

export function setForm(form) {
    return localStorage.setItem('FormKey', form);
}

export function removeForm() {
    return localStorage.removeItem('FormKey');
}