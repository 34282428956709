import request from '@/utils/request'
// 获取用户信息
export function getInfo() {
    return request({
        url: '/sys/getUserInfo',
        method: 'get'
    })
}

/**
 * 获取用户列表
 * @returns 
 */
export function simplePage(data) {
    return request({
        url: '/sys/user/simplePage?current=1&size=10000',
        method: 'post',
        data
    })
}

/** 项目的简单列表，树形结构，用于下拉选择 */
export function projectSimpleList() {
    return request({
        url: '/project/info/simpleList',
        method: 'post'
    });
}

/** 事务的简单列表，用于下拉选择 */
export function affairsSimpleList(data = {}) {
    return request({
        url: '/basic/affairs/simpleList',
        method: 'post',
        data
    });
}

/** 微信auth, 获取授权链接 */
export function getWxAuthUrl(data) {
    return request({
        url: '/auth/getWxAuthUrl',
        method: 'post',
        data
    });
}


/** 微信auth, 根据code登录 */
export function doLoginByWxCode(data) {
    return request({
        url: '/auth/doLoginByWxCode',
        method: 'post',
        data
    });
}

