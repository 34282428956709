import router from './router'
import store from './store'
import { getToken, setToken } from '@/utils/auth';
const whiteList = ["/404","/Login"] //白名单
router.beforeEach((to, from, next) => {
    // setToken('NDNj3eYn2LUnxj2S8ekHMv5BRn8atC5mI74ugfkn2XajvBwFEpeqGWXna7tzEsVv')
    let _token = getToken() || '';
    if (!_token) {
        // 没有token
        if (whiteList.indexOf(to.path) !== -1) {
            // 在免登录白名单，直接进入
            next()
        } else {
            next(`/Login?toPage=${to.path}`) // 否则全部重定向到Login
        }
        return;
    }
    if (to.path === '/404') {
        next({ path: '/' })
        return;
    }
    if (store.getters.roles.length === 0) {
        store.dispatch('GetInfo').then(res => {
            next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
        }).catch(err => {
                store.dispatch('FedLogOut').then(() => {
                    console.log(err);
                    next({ path: '/' })
                })
            })
    } else {
        next();
    }
    
})