import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { isSystem, thousandthMark, getDiffDay, formatDate, arabicNumeralsToCapitalNumerals, numberToFixed2 } from "@/utils/index.js";
import "./permission";
import './directives/preventDbClick.js'
import 'normalize.css/normalize.css'
import Vant from 'vant';
import 'vant/lib/index.css';
// import 'vant/lib/index.less';
import './assets/style/iconfont.css';
import './assets/font/iconfont.css';
import './assets/style/default.scss';

import VConsole from 'vconsole';
import { Toast } from 'vant';
Vue.use(Toast);
Vue.prototype.thousandthMark = thousandthMark;
Vue.prototype.numberToFixed2 = numberToFixed2
Vue.prototype.getDiffDay = getDiffDay;
Vue.prototype.formatDate = formatDate;
Vue.prototype.arabicNumeralsToCapitalNumerals = arabicNumeralsToCapitalNumerals;
// 数字千分符
// 配置全局过滤器，实现数字千分位格式
Vue.filter('numberToCurrency', thousandthMark)
// TODO 生产环境打开
if (process.env.NODE_ENV == "development" && isSystem() !== "window") {
    // if (isSystem() !== "window") {
    new VConsole();
    // }
}
// 全局方法挂载
Vue.use(Vant)

//过滤器
import * as filters from '@/utils/filter/filters.js';
Object.keys(filters).forEach(key=>{
	Vue.filter(key,filters[key])
})

// vue每次版本更新刷页面
const version = require('../package.json').version
const versionStorage = localStorage.getItem('version')

console.log('最新版本', 'v' + version)
console.log('缓存版本', 'v' + versionStorage)

if (version != versionStorage) {
    localStorage.clear()
    localStorage.setItem('version', version)
    console.log('版本不一致，清除缓存')
    //location.reload() 方法用来刷新当前页面。该方法只有一个参数，当值为 true 时，将强从服务器加载页面资源，
    //当值为 false 或者未传参时，浏览器则可能从缓存中读取页面。
    setTimeout(() => {
        window.location.reload(true)
    }, 1000)
}
 
Vue.prototype.goBack = () => {
    router.go(-1);
}
let _loading = null;
Vue.prototype.showLoading = (text = null) => {
    _loading = Toast.loading({
        message: text,
        forbidClick: true,
        overlay: true,
        duration: 0
    });
}
Vue.prototype.hideLoading = () => {
    if (!_loading) return;
    _loading.clear();
}


new Vue({
    router,
    store,
    Vant,
    render: h => h(App)
}).$mount('#app')

window.goBack = function () {
    // this.goBack(); 
    router.go(-1)
}
