<template>
  <div class="app-wrapper">
    <!-- <Navbar/> -->
    <AppMain ref="appMain"  />
    <Footer v-if="tabbarShow" />
  </div>
</template>

<script>
import tabbar from "@/utils/tabbar";
import AppMain from "./components/AppMain.vue";
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";
export default {
  name: "Layout",
  components: {
    AppMain,
    Navbar,
    Footer,
  },
  computed: {
    tabbarShow: function () {
      // console.log(this.$route.name,tabbar);
      let _bool = tabbar.includes(this.$route.name) && this.$route.name != 'Login';
      return _bool;
    },
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variable.scss';
// .appHomePage {
//   // height: $childPageHeight;
//   // height: $homePageHeight;
//   // height: $mainPageHeight;
//   // overflow-x: auto;
//   // background: #fff;
// }
</style>