import { getInfo } from '@/api/index'
import { getToken, setToken, removeToken } from '@/utils/auth'

const user = {
    state: {
        token: getToken(),
        name: '',
        nickName: '',
        roles: [],
        permissions: [],
        workflow_permissions: [],
        userName: '',
        userId:"",
        deptName:'',
        deptId:'',
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_NAME: (state, name) => {
            state.name = name
        },
        SET_NICK_NAME: (state, nickName) => {
            state.nickName = nickName
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles
        },
        SET_PERMISSIONS: (state, permissions) => {
            state.permissions = permissions
        },
        SET_USER_NAME: (state, userName) => {
            state.userName = userName
        },
        SET_USERID: (state, userId) => {
            state.userId = userId
        },
        SET_USERINFO: (state, userInfo) => {
            state.deptName = userInfo.dept?userInfo.dept.deptName:'';
            state.deptId = userInfo.dept?userInfo.dept.deptId:'';
            
        },
        
    },

    actions: {
        // 获取用户信息
        GetInfo({ commit, state }) {
            return new Promise((resolve, reject) => {
                getInfo(state.token).then(response => {
                    let res = response.data;
                    if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
                        commit('SET_ROLES', res.roles)
                        commit('SET_PERMISSIONS', res.permissions)
                    } else {
                        commit('SET_ROLES', ['ROLE_DEFAULT'])
                    }
                    commit('SET_NAME', res.userName)
                    commit('SET_NICK_NAME', res.account)
                    commit('SET_USER_NAME', res.userName)
                    commit('SET_USERID', res.id)
                    commit('SET_USERINFO', res)
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 前端 登出
        FedLogOut({ commit }) {
            return new Promise(resolve => {
                commit('SET_TOKEN', '')
                removeToken()
                resolve()
            })
        }
    }
}

export default user