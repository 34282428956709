<template>
  <div class="navbarBox">
    <div class="navbar">
      <van-nav-bar :left-text="leftBtn ? '返回' : ''" :left-arrow="leftBtn" @click-left="clickLeft" class="title"
        safe-area-inset-top fixed>
        <template>
          <slot slot="title" name="title">{{ title }}</slot>
        </template>
         <template #right v-if="rightIcon">
          <van-icon :name="rightIcon.icon" size="18" style="padding-right: 10px;" />
        </template>
      </van-nav-bar>
    </div>
  </div>
</template>

<script>
import tabbar from "@/utils/tabbar";
import axios from 'axios';
import { getForm } from '@/utils/auth';
// const version = require('element-ui/package.json').version
const defaultSettings = require('@/settings.js')
import { isSystem } from "@/utils/index";
export default {
  name: "Navbar",
  props: {
    // 是否有右侧按钮 按钮的icon名称
    icon: {
      type: String,
      default: () => {
        return "";
      },
    },
    Tips: {
      type: String,
      default: () => {
        return "";
      },
    },
    // 必须传入icon 才可执行； 点击icon执行的函数
    iconEvent: {
      type: String,
      default: () => {
        return "";
      },
    },
	// 页面标题
	pageTitle: {
	  type: String,
	  default: "",
	},
	// 页面标题
	leftClickEvent: {
	  type: Function,
	  default: null,
	},
  },
  computed: {
    leftBtn: function () {
      let _bool = tabbar.includes(this.$route.name);
      return !_bool;
    },
    title() {
      return this.pageTitle?this.pageTitle:this.$route.meta.title;
    },
    rightIcon() { 
      return this.$route.meta.rightIcon;
    }
  },
  data() {
    return {
      index: -1,
    };
  },
  mounted() {
    axios.defaults.headers["Cache-Control"] = 'no-cache';
    if (isSystem() == 'android') { 
      window.worklistner.isShowTitle(0);
    }
  },
  methods: {
    Ios_Excute(funcName, val) {
      var iosurl = 'ios://' + funcName + decodeURIComponent(val);
      window.location.href = iosurl
    },
    // 返回
    clickLeft() {
      if(this.leftClickEvent){
        this.leftClickEvent()
      } else {
        this.goBack();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.navbarBox {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  position: relative;
}
.navbar {
  height: 50px;
  width: 100%;
  border: none;
}

.van-nav-bar {
  background: #f5f5f5;
  color: #000;
  height: 50px;
  z-index: 9999;

  /deep/.van-nav-bar__title,
  /deep/.van-icon,
  /deep/.van-nav-bar__text {
    color: #000;
  }
}

/deep/.van-nav-bar__right {
  padding-right: 0px;
}

.rightIconBox {
  display: flex;
  justify-content: center;
  padding: 10px;
}
</style>
