const include = {
    state: {
        includelist: [],
        history: [],
    },

    mutations: {
        REMOVE_INCLUDELIST: (state, ) => {
            state.includelist = []
            state.history = []
        },
        SET_HISTORY: (state, { name, noCache }) => {
            const _data = state.history.filter(item => {
                return item.name == name
            })
            console.log(state, "eeee");
            if (_data.length == 0) {
                state.history.push({
                    name: name,
                    noCache: noCache
                })
                if (!noCache) {
                    state.includelist.push(name)
                }
                return;
            }

            const _ind = state.history.indexOf(_data[0]);
            let _list = state.history.slice(_ind + 1, state.history.length)
            state.history.splice(_ind + 1, state.history.length);
            let removeIncludelist = _list.filter(item => {
                return !item.noCache
            })
            removeIncludelist.map(item => {
                let _i = state.includelist.indexOf(item.name)
                state.includelist.splice(_i, 1)
            })
        }
    },

    actions: {
        removeInclude({ commit }, {}) {
            commit('REMOVE_INCLUDELIST')
        },
        setHistory({ commit }, { name, noCache }) {
            commit('SET_HISTORY', { name, noCache })
        }
    }
}

export default include