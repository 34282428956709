<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { isSystem } from "@/utils/index";
export default {
  name: "App",
  data() {
    return {};
  },
  created() {
    // plus.navigator.setStatusBarBackground('#6495ED');
    // 在页面加载时读取sessionStorage里的状态信息
    if (localStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(localStorage.getItem("store"))
        )
      );
    }

    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    // beforeunload事件在页面刷新时先触发
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("store", JSON.stringify(this.$store.state));
    });
    const _system = isSystem();
  },
  mounted() {},
  methods: {},
};
</script>
<style>
@import "assets/style/index.scss";

#app {
  background: #fff;
}
</style>

