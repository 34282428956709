<template>
  <div>
    <ul class="footer">
      <li v-for="(item, i) in list" :key="i" :class="item.path == path ? 'hover' : ''">
        <div @click="toRoute(item)">
          <!-- <span></span> -->
          <div class="blockItem">
            <div class="imgBox">
              <img :src="item.path == path ? item.iconSelected : item.icon" alt="" srcset="" />
            </div>
            <p>{{ item.name }}</p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import user from "@/assets/basicModule/user.png";
import userHigh from "@/assets/basicModule/user-high.png";
import meun from "@/assets/basicModule/meun.png";
import meunHigh from "@/assets/basicModule/meun-high.png";
export default {
  name: "Footer",
  data() {
    return {
      list: [
        {
          name: "日报",
          path: "/index",
          img: "",
          icon: user,
          iconSelected: userHigh
        },
        // {
        //   name: "个人",
        //   path: "/my",
        //   img: "",
        //   // icon: require("../../assets/imgs/basicModule/user.png"),
        //   // iconSelected: require("../../assets/imgs/basicModule/user-high.png"),
        // },
        {
          name: "汇报",
          path: "/ReportEdit",
          img: "",
          icon: meun,
          iconSelected: meunHigh
        },

      ],
    };
  },
  computed: {
    path() {
      return this.$route.path;
    },
  },
  mounted() { },
  methods: {
    toRoute(item) {
      if (this.path != item.path) {
        this.$router.replace({
          path: item.path,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  // width: 100%;
  // background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  // height: 50px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  // border-top: 1px solid #dcdfe6;
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  background: #f5f5f5;

  // height: calc(
  //   72px + constant(safe-area-inset-bottom)
  // ); /* 直接扩展高度，因为padding-bottom是内边距 */
  // height: calc(72px + env(safe-area-inset-bottom)); /* 直接扩展高度 */
  // padding-bottom: constant(safe-area-inset-bottom); /*兼容 iOS<11.2 */
  // padding-bottom: env(safe-area-inset-bottom); /* 兼容iOS>= 11.2*/
  li {
    font-size: 14px;
    flex: 1;
    height: 100%;
    // font-weight: bold;
    color: #303133;
    // border-right: 1px solid #303133;
    // cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    span {
      background: #303133;
      width: 1px;
      height: 100%;
      display: inline-flex;
      margin: 0 10px;
    }

    a {
      color: #000;
    }

    .blockItem {
      display: flex;
      flex-direction: column;
      align-items: center;

      .imgBox {
        width: 24px;
        height: 24px;

        img {
          display: block;
          width: 24px;
          height: 24px;
        }
      }

      p {
        text-align: center;
        // height: 24px;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 300;
        color: #000000;
        padding-top: 5px;
      }
    }
  }

  li:last-child {

    // border-right: none;
    span {
      display: none;
    }
  }

  .hover {
    background: #009d8e;

    .blockItem p {
      color: #fff;
    }
  }
}
</style>
