const getters = {
    token: state => state.user.token,
    name: state => state.user.name,
    nickName: state => state.user.nickName,
    introduction: state => state.user.introduction,
    roles: state => state.user.roles,
    permissions: state => state.user.permissions,
    workflow_permissions: state => state.user.workflow_permissions,
    userName: state => state.user.userName,
    userId: state => state.user.userId,
    deptName: state => state.user.deptName,
    deptId: state => state.user.deptId,
    include: state => state.include.includelist,
}
export default getters