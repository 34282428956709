import Vue from 'vue'
// form提交防抖
const preventDbClick = Vue.directive('preventDbClick', {
    inserted: function(el, binding) {
        el.addEventListener('click', () => {
            let bool = el.getAttribute('class').includes("is-disabled")
            console.log(bool, "====");
            if (!bool) {
                el.classList.add("is-disabled")
                setTimeout(() => {
                    el.classList.remove("is-disabled")
                    el.disabled = false
                }, 1000)
            } else {
                // console.log("已经存在");
                el.disabled = true
            }
        })
    }
});

export {
    preventDbClick

}