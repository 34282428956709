import Vue from 'vue'
import VueRouter from 'vue-router'
import LayOut from '@/layout'
Vue.use(VueRouter)

export const constantRoutes = [
	{
        path: '/',
        redirect: "/index", //重定向
        component: LayOut,
        children: [
            {
                path: '/my',
                name: "my",
                component: () => import("@/views/BasicModule/my.vue"),
                hidden: true,
                meta: {
                    title: "我的",
                    noCache: true
                },
            },
            {
                path: "/index",
                name: "index",
                component: () => import("@/views/daily/list.vue"),
                meta: {
                    title: "日报",
                    noCache: true,
                }
            },
            {
                path: "/ReportEdit",
                name: "ReportEdit",
                component: () => import("@/views/daily/add.vue"),
                meta: {
                    title: "汇报",
                    noCache: true,
                }
            },
            {
                path: "/Login",
                name: "Login",
                component: () => import("@/views/Login.vue"),
                meta: {
                    title: "登录",
                    noCache: true,
                }
            }


        ]
    },
    {
        path: '/404',
        name: "404",
        component: (resolve) => require(['@/views/BasicModule/404'], resolve),
        hidden: true,
        meta: {
            title: "404",
            noCache: true
        },
    },
    
]


const createRouter = () => new VueRouter({
    mode: 'history', // 去掉url中的#
    routes: constantRoutes,
    scrollBehavior(to, from, savePosition) {
        if (savePosition) {
            return savePosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

const router = createRouter()

export default router

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // the relevant part
}