import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import getters from './getters'
import include from './modules/include'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: { user, include },
    getters
})

export default store